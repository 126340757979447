<template>
  <div class="invoice-wrap">
    <div class="invoice-info">
      <div class="info">
        <el-row>
          <el-col :span="8">
            <label>发票类型：</label>
            <span>{{fmtBillingType(info.billingType)}}</span>
          </el-col>
          <el-col :span="8">
            <label>发票状态：</label>
            <span>{{fmtInvoiceStatus(info.invoiceStatus)}}</span>
          </el-col>
          <el-col :span="8">
            <label>信息表状态：</label>
            <span>未申请</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <label>发票代码：</label>
            <span>{{info.invoiceCode}}</span>
          </el-col>
          <el-col :span="8">
            <label>发票号码：</label>
            <span>{{info.invoiceNo}}</span>
          </el-col>
          <el-col :span="8">
            <label>开票时间：</label>
            <span>{{info.invoiceTime}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <label>价税合计：</label>
            <span>{{info.sumAmount}}</span>
          </el-col>
        </el-row>
      </div>
      <div class="source">
        <div class="buyer">
          <i>购买方</i>
          <el-row>
            <el-col>
              <label>名称：</label>
              <span>{{info.buyerName}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <label>税号：</label>
              <span>{{info.buyerTaxNo}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <label>地址电话：</label>
              <span>{{info.buyerAddressTel}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <label>开户行及账号：</label>
              <span>{{info.buyerBankAccount}}</span>
            </el-col>
          </el-row>
        </div>
        <div class="seller">
          <i>销售方</i>
          <el-row>
            <el-col>
              <label>名称：</label>
              <span>{{info.sellerName}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <label>税号：</label>
              <span>{{info.sellerTaxNo}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <label>地址电话：</label>
              <span>{{info.sellerAddressTel}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <label>开户行及账号：</label>
              <span>{{info.sellerBankAccount}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="invoice-footer">
      <el-button type="default" @click="$emit('cancel')">取消</el-button>
      <el-button type="primary" @click="$emit('confirm')" :disabled="info.submitDisabled">申请</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BillingInvoiceDialog',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    fmtBillingType (val) {
      return this.handleValueToLabel('BillingType', val)
    },
    fmtInvoiceStatus (val) {
      return this.handleValueToLabel('InvoiceStatus', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    width: 84px;
    color: #666666;
    display: inline-block;
  }

  span {
    color: #333333;
    display: inline-block;
  }

  .el-row {
    padding-bottom: 12px;

    .el-col {
      display: flex;
      text-align: left;
      flex-direction: row;
    }

    .sum-amount {
      font-weight: 500;
    }
  }

  .invoice-wrap {
    font-size: 14px;
    margin-top: -20px;
    padding: 0 24px 24px;

    .info {
      padding-bottom: 32px;
    }

    .source {
      display: flex;
      text-align: left;
      padding-top: 32px;
      flex-direction: row;
      align-items: flex-start;
      border-top: 1px solid #E5E5E5;

      .buyer, .seller {
        width: 50%;
      }

      i {
        color: #333333;
        font-weight: 500;
        font-style: normal;
        padding-bottom: 16px;
      }

      label {
        width: auto;
        word-break: keep-all;
      }
    }

    .invoice-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
</style>