<template>
  <el-form class="info-wrap" ref="infoForm" :model="info" :rules="infoRules" label-position="top">
    <div class="info-source">
      <el-form-item prop="applicant">
        <el-radio-group class="applicant" v-model="info.applicant" @change="clearFormValidate">
          <el-radio label="buyer">购买方申请</el-radio>
          <el-radio label="seller">销售方申请</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="deduction">
        <el-radio-group class="deduction" v-model="info.deductionType" :disabled="disabledRadio"
                        @change="clearFormValidate">
          <el-radio label="1">已抵扣</el-radio>
          <el-radio label="0">未抵扣</el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <div class="info-content">
      <span>对应蓝字增值税专用发票信息</span>
      <div>
        <el-form-item prop="billingType" label="发票种类">
          <el-select v-model="info.billingType">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="invoiceCode" label="发票代码">
          <el-input type="tel" v-model.trim="info.invoiceCode"
                    maxlength="12"
                    placeholder="请输入发票代码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="invoiceNo" label="发票号码">
          <el-input type="tel" v-model.trim="info.invoiceNo"
                    maxlength="8"
                    placeholder="请输入发票号码"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="!disabledRadio" prop="invoiceTime" label="开票时间">
          <el-date-picker
            class="date-picker"
            v-model="info.invoiceTime"
            type="date"
            placeholder="请选择时间"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="businessSign" label="营业税标志" v-if="this.billingChannel == 'HX-FWQ-JSP'">
          <el-select v-model="info.businessSign" placeholder="请选择营业税标志">
            <el-option v-for="item in businessSigns"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="info-footer">
      <el-button type="default" @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </el-form>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'

export default {
  name: 'RedInfoDialog',
  computed: {
    disabledRadio () {
      return this.info.applicant == 'seller'
    },
    disabledInput () {
      return this.info.applicant == 'buyer' && this.info.deductionType == '1'
    }
  },
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      alreadyOption: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 1000 * 60 * 60 * 24 * 45
        }
      },
      options: [{
        value: 'Zzzp',
        label: '纸质专票'
      }],
      billingChannel: localStorage.getItem('BILLING_MODE'),
      info: {
        applicant: 'buyer',// 申请方类型
        deductionType: '1', // 抵扣状态
        billingType: 'Zzzp',
        invoiceTime: '',
        invoiceCode: '',
        invoiceNo: '',
        businessSign: 'Qt'
      },
      infoRules: {
        billingType: [{ required: true, message: '发票类型不可为空!', trigger: 'blur' }],
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' },
          { min: 10, max: 12, message: '请输入10-12位的发票代码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceCode, message: '请输入正确的发票代码' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' },
          { min: 8, max: 8, message: '请输入8位发票号码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceNo, message: '请输入正确的发票号码' }
        ],
        invoiceTime: [{ required: true, message: '开票时间不可为空!', trigger: 'blur' }],
      },
      billingTypes: [
        { label: '纸质专票', value: 'Zzzp' },
        { label: '电子专票', value: 'Dzzp', disabled: true }],
      businessSigns: [
        { label: '1.5%税率', value: 'Tsl' },
        { label: '差额税', value: 'Ces' },
        { label: '农产品收购', value: 'Nsg' },
        { label: '成品油', value: 'Cpy' },
        { label: '其他', value: 'Qt' }]
    }
  },
  methods: {
    // 输入框仅能输入数字
    handleInput (val) {
      return val.replace(/[^0-9]/g, '')
    },
    // 点击确定触发
    handleConfirm () {
      this.$refs.infoForm.validate(val => {
        if (val) this.$emit('confirm', this.info)
      })
    },
    // 点击取消触发
    handleCancel () {
      this.$refs.infoForm.resetFields()
      this.$emit('cancel')
    },
    // 切换radio 触发重置校验结果
    clearFormValidate () {
      this.$refs.infoForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-wrap {
    display: flex;
    font-size: 14px;
    margin-top: -20px;
    flex-direction: column;
    align-items: flex-start;

    .info-source {
      padding: 0 24px;

      .el-radio {
        color: #A7643B;
        margin-right: 0
      }

      .applicant {
        width: 240px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .deduction {
        display: flex;
        margin-top: -10px;
        padding-left: 24px;
        flex-direction: column;
        align-items: flex-start;

        .el-radio {
          color: #666666;
          margin-top: 8px;
        }
      }
    }

    .info-content {
      width: 100%;
      display: flex;
      margin-top: 32px;
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid #E5E5E5;

      span {
        color: #333333;
        margin-top: 24px;
        font-weight: 500;
        padding-left: 24px;
        display: inline-block;
      }
    }

    .info-content > div {
      padding: 0 24px;
      text-align: left;

      ::v-deep .el-input {
        width: 312px;
      }

      ::v-deep .el-form-item__label {
        margin-top: 20px;
      }

      ::v-deep .el-form-item__error {
        top: 34px;
        font-size: 12px;
      }
    }

    .info-footer {
      width: 300px;
      display: flex;
      flex-direction: row;
      padding: 36px 24px 24px;
      justify-content: flex-end;
    }
  }
</style>
